var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"container-content"},[_c('div',{staticClass:"page-header"},[_c('h4',{staticClass:"page-header-text mb-3"},[_vm._v(_vm._s(_vm.title))]),_c('span',[_vm._v(_vm._s(// isFromOTM
      //   ? $t('form_label.please_choose_maid_first')
      //   : $t('form_label.please_choose_maid_multi')
      _vm.$t('form_label.please_choose_maid_first')))])]),_c('FilterMaid',{attrs:{"options":_vm.filterData,"total":_vm.total_maids},on:{"onOptionsChange":_vm.onOptionsChange}}),_c('b-row',[(_vm.maidsAdminRecommend.length > 0)?_c('b-col',{staticClass:"recommend-color text-center font-weight-bold text-dark admin-recommend",attrs:{"xl":_vm.maidsAdminRecommend.length > 3 ? 12 : _vm.maidsAdminRecommend.length * 3,"lg":_vm.maidsAdminRecommend.length > 2 ? 12 : _vm.maidsAdminRecommend.length * 4,"md":_vm.maidsAdminRecommend.length > 1 ? 12 : _vm.maidsAdminRecommend.length * 6,"cols":"12"}},[_vm._v("\n      "+_vm._s(_vm.$t('form_label.admin_recommend'))+"\n    ")]):_vm._e()],1),_c('b-row',[_vm._l((_vm.maidsAdminRecommend),function(maid,index){return (_vm.maidsAdminRecommend.length > 0)?_c('b-col',{key:`maid-recommend-${index}`,staticClass:"recommend-color",class:_vm.getBorderRadiusClass(index, _vm.maidsAdminRecommend.length),attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('maid',{staticClass:"recommend-card",attrs:{"detail":maid,"hasButton":true,"selectType":_vm.selectType}})],1):_vm._e()}),_vm._l((_vm.maids.filter(
        (maid) => !_vm.maidsAdminRecommend.some((m) => m.id === maid.id)
      )),function(maid,index){return _c('b-col',{key:`maid-${index}`,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('maid',{attrs:{"detail":maid,"hasButton":true,"selectType":_vm.selectType}})],1)}),_vm._l((_vm.maidsFBFriend.filter(
        (maid) => !_vm.maidsAdminRecommend.some((m) => m.id === maid.id)
      )),function(maid,index){return _c('b-col',{key:_vm.maidKey('maid-friend', index),attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('maid',{attrs:{"detail":maid,"hasButton":true,"selectType":_vm.selectType}})],1)})],2),(_vm.isFetchingMaid)?_c('b-row',_vm._l((12),function(i){return _c('b-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('maid',{attrs:{"isLoading":_vm.isFetchingMaid,"detail":null}})],1)}),1):_vm._e(),(_vm.showLoadMoreButton && !_vm.waiting)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"w-100 text-center"},[_c('LoadingSpinner',{staticStyle:{"margin-top":"10px","height":"80px"},attrs:{"label":"Loading..."}})],1):_vm._e(),(_vm.isNoMaidAvailable && !_vm.isFetchingMaid)?_c('div',{staticClass:"text-center"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('no_data.maid_high_demand'))}}),_c('b-link',{on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.$t('button.go_back')))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }