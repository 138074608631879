<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text mb-3">{{ title }}</h4>
      <span>{{
        // isFromOTM
        //   ? $t('form_label.please_choose_maid_first')
        //   : $t('form_label.please_choose_maid_multi')
        $t('form_label.please_choose_maid_first')
      }}</span>
    </div>
    <FilterMaid :options="filterData" @onOptionsChange="onOptionsChange" :total="total_maids" />
    <b-row>
      <b-col
        v-if="maidsAdminRecommend.length > 0"
        :xl="maidsAdminRecommend.length > 3 ? 12 : maidsAdminRecommend.length * 3"
        :lg="maidsAdminRecommend.length > 2 ? 12 : maidsAdminRecommend.length * 4"
        :md="maidsAdminRecommend.length > 1 ? 12 : maidsAdminRecommend.length * 6"
        cols="12"
        class="recommend-color text-center font-weight-bold text-dark admin-recommend"
      >
        {{ $t('form_label.admin_recommend') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="maidsAdminRecommend.length > 0"
        class="recommend-color"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsAdminRecommend"
        :key="`maid-recommend-${index}`"
        :class="getBorderRadiusClass(index, maidsAdminRecommend.length)"
      >
        <maid class="recommend-card" :detail="maid" :hasButton="true" :selectType="selectType"/>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maids.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="`maid-${index}`"
      >
        <maid :detail="maid" :hasButton="true" :selectType="selectType"/>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(maid, index) in maidsFBFriend.filter(
          (maid) => !maidsAdminRecommend.some((m) => m.id === maid.id)
        )"
        :key="maidKey('maid-friend', index)"
      >
          <maid :detail="maid" :hasButton="true" :selectType="selectType"/>
      </b-col>
    </b-row>
    <b-row v-if="isFetchingMaid">
      <b-col cols="12" md="6" lg="4" xl="3" v-for="i in 12" :key="i">
        <maid :isLoading="isFetchingMaid" :detail="null" />
      </b-col>
    </b-row>
    <!-- <b-button variant="link" block @click="loadMoreMaids" v-if="showLoadMoreButton">{{
      $t('button.load_more')
    }}</b-button> -->
    <div v-if="showLoadMoreButton && !waiting" v-observe-visibility="visibilityChanged" class="w-100 text-center">
      <LoadingSpinner label="Loading..." style="margin-top: 10px; height: 80px;"></LoadingSpinner>
    </div>
    <div v-if="isNoMaidAvailable && !isFetchingMaid" class="text-center">
      <p v-html="$t('no_data.maid_high_demand')"></p>
      <b-link @click="goBack">{{ $t('button.go_back') }}</b-link>
    </div>
  </b-container>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import Maid from '../../components/Maid'
import FilterMaid from '../../components/FilterMaid'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ENABLE_FILTER_MAID_BY_USER_LOCATION } from '../../config/booking'
import { filterMaidsMixin } from '../../mixins/filterMaid'
import pick from 'lodash/pick'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'

const BookingStore = createNamespacedHelpers('booking')
const MaidStore = createNamespacedHelpers('maid')
const AuthStore = createNamespacedHelpers('auth')

@Component({
  components: { Maid, FilterMaid, LoadingSpinner },
  computed: {
    ...AuthStore.mapState(['isLoggedIn']),
    ...BookingStore.mapState(['oneTime', 'multiPackage']),
    ...MaidStore.mapState(['isFetchingMaids', 'isFetchingMaidsFriend']),
    ...MaidStore.mapGetters(['maids', 'maidsFBFriend', 'maidsAdminRecommend', 'totalMaidsFriend', 'totalMaids']),
  },
  methods: {
    ...MaidStore.mapActions(['getMaids', 'getMaidsFriend', 'getMaidsAdminRecommend']),
    ...MaidStore.mapMutations(['resetMaidsList']),
  },
  mixins: [filterMaidsMixin],
})
export default class SelectMaid extends Vue {
  totalMaidPages = -1
  currentMaidsPage = 0
  totalMaidFriendPage = -1
  currentMaidsFriendPage = 0
  isLastPageOfTotalMaid = false
  isLastPageOfMaidFriend = false

  isFetching = false

  waiting = false
  showMaidList = true

  get isFromOTM() {
    console.log('window', window.location.search.includes('one-time'))
    return window.location.search.includes('one-time')
  }

  get showLoadMoreButton() {
    return this.isLoggedIn ? !this.isLastPageOfMaidFriend : !this.isLastPageOfTotalMaid
  }

  get isNoMaidAvailable() {
    return this.isLoggedIn ? this.maidsFBFriend.length === 0 : this.maids.length === 0
  }

  get isFetchingMaid() {
    return this.isFetchingMaids || this.isFetchingMaidsFriend
  }

  // Computed declaration
  get selectType() {
    return this.$route.query.type
  }

  get title() {
    return this.selectType === 'multi-package'
      ? this.$t('commons.book_multi_package')
      : this.$t('commons.book_one_time')
  }

  // fix me - don't forget to load api 20 - maidListWithFriend

  // Methods
  async mounted() {
    window.addEventListener('resize', this.handleResize)
    localStorage.isFromSelectMaid = 'select-maid'
    if (!this.selectType) {
      this.$router.push({ name: 'Home' })
      return
    }
    this.resetMaidsList()
    const callback = this.$route.query.callback
    const isNewBooking =
      callback.includes('book-one-time') || callback.includes('book-multi-package')
    isNewBooking && this.$store.commit('maid/resetMaidHiddenList')

    if (this.$route.query.services) {
      if (
        this.$route.query.services == 'DRAPERY' ||
        this.$route.query.services == 'CLEANING_DRAPERY'
      ) {
        this.filterData.canIron = true
      }
    }

    if (this.withBringEquipmentOptions.bring_equipment) {
      this.filterData.bring_equipment = true
    }

    await this.getListMaidsAdminRecommend()
    await this.loadMoreMaids()
  }

  async loadMoreMaids() {
    if (this.waiting) return
    this.waiting = true
    if (this.isLoggedIn) {
      await this.getMaidListWithFriends()
    } else {
      await this.getMaidList()
    }
    this.waiting = false
  }

  visibilityChanged(isVisible) {
    if (!isVisible) {
      return
    }
    this.loadMoreMaids()
  }

  get total_maids() {
    return this.isLoggedIn ? this.totalMaidsFriend : this.totalMaids
  }

  async getMaidList() {
    let options = {
      ...this.baseOptions,
      ...this.withDateTimeOptions,
      ...this.withLocationOptions,
      // ...this.withBringEquipmentOptions,
      page: this.currentMaidsPage + 1,
    }

    options = pick(options, this.params) // pick by whitelist keys
    options = omitBy(options, (val) => !val) // emit falsy

    const { page_count } = await this.getMaids({ options })
    this.totalMaidPages = page_count
    this.currentMaidsPage += 1
    this.isLastPageOfTotalMaid = this.currentMaidsPage === page_count || page_count === 0
  }

  async getListMaidsAdminRecommend() {
    let options

    if (this.isLoggedIn) {
      options = {
        ...this.getMaidListWithFriendsOptions(),
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: 1,
      }
    } else {
      options = { 
        ...this.baseOptions,
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: 1 
      }
    }

    options = pick(options, this.params)
    options = omitBy(options, (val) => !val)
    await this.getMaidsAdminRecommend({ options, isLoggedIn: this.isLoggedIn })
  }

  async onOptionsChange(options) {
    this.totalMaidPages = -1
    this.currentMaidsPage = 0
    this.totalMaidFriendPage = -1
    this.currentMaidsFriendPage = 0
    let tempOptions = { ...options }
    if (this.$route.query.services) {
      if (
        this.$route.query.services == 'DRAPERY' ||
        this.$route.query.services == 'CLEANING_DRAPERY'
      ) {
        tempOptions.canIron = true
      }
    }

    if (this.withBringEquipmentOptions.bring_equipment) {
      tempOptions.bring_equipment = true
    }

    this.filterData = tempOptions
    this.resetMaidsList()
    await this.getListMaidsAdminRecommend()
    await this.loadMoreMaids()
  }

  async getMaidListWithFriends() {
    if (this.isLoggedIn) {
      let options = {
        ...this.getMaidListWithFriendsOptions(),
        ...this.withDateTimeOptions,
        ...this.withLocationOptions,
        // ...this.withBringEquipmentOptions,
        page: this.currentMaidsFriendPage + 1,
      }

      options = pick(options, this.params) // pick by whitelist keys
      options = omitBy(options, (val) => !val) // emit falsy values
      const { page_count } = await this.getMaidsFriend({ options })

      this.totalMaidFriendPage = page_count
      this.currentMaidsFriendPage += 1
      this.isLastPageOfMaidFriend = this.currentMaidsFriendPage === page_count || page_count === 0
    }
  }

  get withDateTimeOptions() {
    const params = this.$route.query
    const { start_datetime, end_datetime } = params
    if (start_datetime && end_datetime) {
      return { start_datetime, end_datetime }
    }
    return {}
  }

  get withLocationOptions() {
    if (!ENABLE_FILTER_MAID_BY_USER_LOCATION) {
      return {}
    }

    const params = this.$route.query
    const { location_latitude, location_longitude } = params
    if (location_latitude && location_longitude) {
      return { location_latitude, location_longitude }
    }

    return {}
  }

  get withBringEquipmentOptions() {
    const params = this.$route.query
    return { bring_equipment: parseInt(get(params, 'bring_equipment', '0')) === 1 }
  }

  goBack() {
    this.$router.go(-1)
  }

  beforeDestroy() {
    this.resetMaidsList()
    window.removeEventListener('resize', this.handleResize)
  }

  getBorderRadiusClass(index, length) {
    if (!this.showMaidList) {
      return ''
    }
    const mediaSize = window.innerWidth
    if (mediaSize < 768) {
      return index === length - 1 ? 'rounded-recommend-bottom' : ''
    }

    if (mediaSize < 992) {
      const lastRowHasOneItem = length % 2 === 1
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize < 1200) {
      const lastRowHasOneItem = length % 3 === 1
      const lastRowHasTwoItem = length % 3 === 2
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }

    if (mediaSize >= 1200) {
      const lastRowHasOneItem = length % 4 === 1
      const lastRowHasTwoItem = length % 4 === 2
      const lastRowHasThreeItem = length % 4 === 3
      if (lastRowHasOneItem){
        if(index === length - 1){
          return 'rounded-recommend-bottom'
        }
        if(index === length - 2){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasTwoItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 2){
          return 'rounded-recommend-left'
        }
        if(index === length - 3){
          return 'rounded-recommend-right'
        }
      } else if (lastRowHasThreeItem){
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 3){
          return 'rounded-recommend-left'
        }
        if(index === length - 4){
          return 'rounded-recommend-right'
        }
      } else {
        if(index === length - 1){
          return 'rounded-recommend-right'
        }
        if(index === length - 4){
          return 'rounded-recommend-left'
        }
      }
      return ''
    }
  }

  resizeTimeout = null
  handleResize() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        this.showMaidList = false
        setTimeout(() => {
          this.showMaidList = true
        }, 0)
      }, 100)
    }
  }

  maidKey(prefix, index) {
    return `${prefix}-${index}`
  }
}
</script>
<style lang="scss" scoped>
.admin-recommend {
  font-size: 1.2em;
  border-radius: 6px 6px 0 0;
  padding-top: 1em;
  padding-bottom: calc(1em - 10px);
}

.recommend-color {
  background-color: rgba(255, 194, 207, 0.373);
}

.recommend-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rounded-recommend-right {
  border-radius : 0 0 6px 0;
}

.rounded-recommend-left {
  border-radius: 0 0 0 6px;
}

.rounded-recommend-bottom {
  border-radius: 0 0 6px 6px;
}

@media (max-width: 767px) {
  .admin-recommend {
    font-size: 1em;
  }
}
</style>